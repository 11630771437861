import analytics from '@lib/gtm/analytics'
import { Sale, Category, Maybe } from 'src/generated/graphql'
import { pathOr, pipe, join, map } from 'ramda'
import {
  GA4CartProducts,
  GACartProducts
} from '@concepts/Checkout/types/Checkout'
import { SaleDetailsType } from '@concepts/Sales/types/sale'

export type AnalyticsSale = {
  databaseId?: Maybe<number>
  name?: string
  title?: string
  priceInCents: number
}

type ImpressionSale = {
  category: string
  id: string
  name: string
  price: number
  position: string
  list?: string
}

type ImpressionSaleGA4 = {
  item_category: string
  item_id: string
  item_name: string
  price: number
  item_position: string
  list?: string
}

type DetailSale = {
  actionField: { list: string }
  products: Array<{
    category: string
    id: string
    name: string
    price: string
  }>
}

type ActionFieldClick = {
  list: string
}

type EcommerceClick = {
  actionField: ActionFieldClick
  products: Array<ImpressionSale>
}

type CheckoutAnalyticsProducts = {
  products: Array<GACartProducts>
}

type Ecommerce = {
  impressions?: Array<ImpressionSale>
  click?: EcommerceClick
  checkout?: CheckoutAnalyticsProducts
  add?: CheckoutAnalyticsProducts
  remove?: CheckoutAnalyticsProducts
  detail?: DetailSale
}

type EventAttrs = {
  ecommerce?: Ecommerce
  categorySales?: Array<Sale>
  previouslyPurchasedProducts?: boolean
}

type GA4Product = {
  item_id?: string
  item_name?: string
  item_category?: string
  price: number
}

type GA4Ecommerce = {
  impressions?: Array<ImpressionSaleGA4>
  item_list_id?: number
  item_list_name?: string
  items?: Array<GA4Product>
}

type GA4EventAttrs = {
  ecommerce?: GA4Ecommerce
}

const track = (action: string, attrs?: EventAttrs | GA4EventAttrs): void => {
  analytics.dataLayer({ ecommerce: null })
  analytics.trackSCDataLayer(action, attrs)
}

type EcommerceAnalytics = {
  trackImpression: (items: Array<AnalyticsSale>, module: string) => void
  trackProductDetails: (items: SaleDetailsType, module: string) => void
  trackProductClick: (
    item: AnalyticsSale,
    module: string,
    position: number
  ) => void
  trackUserVisit: (previousPurchases: boolean) => void
  trackV2HomePageViewed: () => void
  trackV2CategoryViewed: (sales: Array<Sale>) => void
  trackCheckout: (products: Array<GACartProducts>) => void
  trackAddToCart: (products: Array<GACartProducts>) => void
  trackRemoveFromCart: (products: Array<GACartProducts>) => void
}

const toCategoryName = (item: Category) => item.name

const parseProductToGA4 = (
  item: AnalyticsSale,
  position: number
): ImpressionSaleGA4 => ({
  item_category: pipe(
    pathOr([], ['categories']),
    map(toCategoryName),
    join('/')
  )(item) as string,
  item_id: String(item.databaseId),
  item_name: item.name || item.title || '',
  price: (item.priceInCents || 0) / 100,
  item_position: String(position)
})

const parseProductItemsGA4 = (
  items: Array<AnalyticsSale>
): Array<ImpressionSaleGA4> => {
  return items.map((item, index: number) => parseProductToGA4(item, index + 1))
}

const parseProduct = (
  item: AnalyticsSale,
  position: number,
  module?: string
): ImpressionSale => ({
  category: pipe(
    pathOr([], ['categories']),
    map(toCategoryName),
    join('/')
  )(item) as string,
  id: String(item.databaseId),
  name: item.name || item.title || '',
  price: (item.priceInCents || 0) / 100,
  position: String(position),
  ...(module ? { list: module } : {})
})

const parseProductItems = (
  items: Array<AnalyticsSale>,
  module: string
): Array<ImpressionSale> => {
  return items.map((item, index: number) =>
    parseProduct(item, index + 1, module)
  )
}

const productsToGA4 = (
  products: Array<GACartProducts>
): Array<GA4CartProducts> => {
  return products.map((product) => ({
    item_category: product.category,
    item_id: product.id,
    item_name: product.name,
    price: product.price,
    quantity: product.quantity
  }))
}

const ecommerceAnalytics: EcommerceAnalytics = {
  trackImpression: (items, module) => {
    track('productImpression', {
      ecommerce: {
        impressions: parseProductItems(items, module)
      }
    })

    track('productImpressionGA4', {
      ecommerce: {
        item_list_name: module,
        items: parseProductItemsGA4(items)
      }
    })
  },
  trackProductDetails: (item, module) => {
    track('productDetail', {
      ecommerce: {
        detail: {
          actionField: {
            list: module
          },
          products: [
            {
              id: String(item.id),
              name: item.name,
              category: item.category.name,
              price: String(item.priceInCents / 100)
            }
          ]
        }
      }
    })

    track('productDetailGA4', {
      ecommerce: {
        items: [
          {
            item_id: String(item.id),
            item_name: item.name,
            item_category: item.category.name,
            price: item.priceInCents / 100
          }
        ]
      }
    })
  },
  trackProductClick: (product, module, position) => {
    track('productClick', {
      ecommerce: {
        click: {
          actionField: {
            list: module
          },
          products: [parseProduct(product, position)]
        }
      }
    })

    track('productClickGA4', {
      ecommerce: {
        item_list_id: position,
        item_list_name: module,
        items: [
          {
            item_id: String(product.databaseId),
            item_name: product.name,
            price: product.priceInCents
          }
        ]
      }
    })
  },
  trackUserVisit: (hasPreviousPurchase) => {
    track('USER_VISITS', { previouslyPurchasedProducts: hasPreviousPurchase })
  },
  trackV2HomePageViewed: () => {
    track('V2_HOME_PAGE_VIEWED')
  },
  trackV2CategoryViewed: (sales) => {
    track('V2_CATEGORY_VIEWED', {
      categorySales: sales
    })
  },
  trackCheckout: (products) => {
    track('onCheckout', {
      ecommerce: {
        checkout: {
          products
        }
      }
    })

    track('onCheckoutGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  },
  trackAddToCart: (products) => {
    track('addToCart', {
      ecommerce: {
        add: {
          products
        }
      }
    })

    track('addToCartGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  },
  trackRemoveFromCart: (products) => {
    track('removeFromCart', {
      ecommerce: {
        remove: {
          products
        }
      }
    })

    track('removeFromCartGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  }
}

export default ecommerceAnalytics
