export const getSegmentConfig = (
  segmentFacebookTrack: boolean,
  segmentClientSideKey: string
) => {
  const isSegmentFacebookEnabled = Boolean(
    segmentFacebookTrack && segmentClientSideKey
  )

  return { key: segmentClientSideKey, isSegmentFacebookEnabled }
}
